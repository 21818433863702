<template>
  <div style="width:100%;background-color: #000;height: 100vh;">
    <transition name="slide-fade">
    <div class="emojiBox" v-show="showEmoji">
      <div v-for="emoji in emojiName" :key="emoji" @click="chooseEmoji(emoji)">
        <img :src="emojiUrl + emojiMap[emoji]" />
      </div>
    </div>
    </transition>
    <el-row>
      <el-col :span="24">
        <div
          style="height: 50px;display: flex;justify-content: space-between;border: 1px #1c2131 solid;padding: 0 116px 0 20px;align-items: center;">
          <img src="../../assets/image/whimgnewLogo.png" class="logo" />

          <div style="display: flex;align-items: center;">
            <el-button type="primary" size="small" @click="endPlay">退出</el-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src="../../assets/img/avatar.png" class="avatar" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="18">
        <div style="height:94vh;" @mousedown="showEmoji=false">
          <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="playsinline"
            :options="playerOptions" @play="startPlay" @ended="endedPlay" @pause="pausePlay">
          </video-player>
        </div>
      </el-col>
      <el-col :span="6">
        <el-tabs v-model="activeName">
          <el-tab-pane label="讨论区" name="first" style="color: #8a9099;">
            <div style="height:85vh;display: flex;flex-direction: column;" @mousedown="showEmoji=false">
              <div style="flex-grow: 1;padding: 14px 22px;height: 30px;line-height: 30px;overflow: auto;">
                <div v-for="(item,index) in chats" :key="index" style="margin-bottom: 10px;">
                <div style="display: flex;font-size: 14px;align-items: center;">
                  <div style="color: #fff;margin-right: 12px;">{{item.userName}}</div>
                  <div style="color: #8a9099;">{{item.createTime}}</div>
                </div>
                <div
                  style="background-color:rgba(223,223,223,0.05);padding: 8px 12px;font-size: 16px;color: #fff;word-break: break-all;">
                 <span v-html="messageProcessing(item.content)"></span>
                </div>
                </div>
              </div>
              <div style="height: 200px;border-top: 2px #454c5e solid;position: relative;">

                  <svg @click="showEmoji=!showEmoji" aria-hidden="true" style="fill: #fff;width: 24px;position: absolute;top:-53px;left: 10px;cursor: pointer;"><use data-v-4c37f42e="" xlink:href="#icon-emoji"></use></svg>
                  <textarea @focus="showEmoji=false" @keyup.enter="sendMsg" v-model="form.inputValue" style="font-size: 16px;height: 95px;width: 100%;top: 45px ;padding:4px;border:none;background:#383f54;color:#8a9099;position: absolute;bottom: 0;outline:none;"></textarea>
                 <div style="position: absolute;bottom: 0;width: 100%;text-align: right;padding: 10px;">
                   <el-button type="primary" @click="sendMsg">发送</el-button>
                 </div>

              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="直播介绍" name="second"
            style="color: #fff;padding: 20px 25px 25px;font-size: 16px;color: #8a9099;">
            <p v-html="resourceInfo.introduce"></p>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

  </div>
</template>
<script>
  // eslint-disable-next-line no-unused-vars
  import {
    getYunTelecastLubo_api,
    saveTelecastTeacherHour_api,
    getLength_api,
    saveTelecastChat_api
  } from '@/api/live';
  import {
    emojiMap,
    emojiName,
    emojiUrl
  } from '@/utils/emojiMap';
  import {
    sessionGet
  } from "utils/local";
  import {
    dateFormat
  } from "utils/dateFormat";
  export default {
    name: 'Details',
    data: () => {
      return {
        showEmoji:false,//显示emoji表情
        chats:[

        ],//聊天信息
        form:{
          inputValue:''
        },
        emojiMap,
        emojiName,
        emojiUrl,
        activeName: 'first',
        resourceInfo: '',
        enclosureList: [],
        token: sessionGet('tk'),
        Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        // 播放器配置
        playsinline: true,
        playerOptions: {
          // playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          // controls: true, //是否显示控制条
          autoplay: true, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: 'video/mp4',
            src: '' // url地址
          }],
          poster: '', // 封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            // timeDivider: true, // 当前时间和持续时间的分隔符
            // durationDisplay: true, // 显示持续时间
            // remainingTimeDisplay: false, // 是否显示剩余时间功能
            // fullscreenToggle: true // 是否显示全屏按钮
            volumeControl: true
          }
        },
        timerId: '', //计时器id
        timer: 0, //计时器
        duration: '', //学时时长
        websocket:null
      }
    },
    created() {
      if ('WebSocket' in window) {
          this.websocket = new WebSocket('wss://www.wuhousmartedu.com/socket/webSocket');
      } else {
          alert('该浏览器不支持websocket');
      }
    },
    mounted() {
      this.websocket.onopen=()=>{
         console.log('建立连接')
      }
      this.websocket.onclose=()=>{
         console.log('连接关闭')
      }
      this.websocket.onmessage=(ev)=>{
        if(this.isJson(ev.data)){
          this.chats.push(JSON.parse(ev.data))
        }
      }
      getLength_api().then(res => {
        this.duration = res.data
      })
      this.resourceDetails();
      this.watchTabUnload();
    },
    beforeDestroy() {
      console.log('leave')
      //移除刷新事件
      this.websocket.close();
      clearInterval(this.timerId);
      window.onbeforeunload=undefined;
    },
    methods: {
      //浏览器刷新或关闭
      watchTabUnload() {
        var that=this;
        window.onbeforeunload = function (e) {
            e = e || window.event;
            that.websocket.close();
             if (sessionGet('userInfo').role_name == 'teacher') {
                that.saveTelecastTeacherHour();
             }
            var timer2 = setInterval(function(){
                that.fun1();
                clearInterval(timer2);
            }, 1000);     //在点击取消关闭页面按钮时启动计时器，执行一次就暂停
            if (e) {
                e.returnValue = '关闭提示';
            }
            return '关闭提示';
        };
        window.addEventListener("beforeunload", function (event) {
            event.returnValue = "关闭提示";
        });
      //   if (sessionGet('userInfo').role_name == 'teacher') {
      //     let that = this;
      //     window.onbeforeunload = function(e) {
      //       e = e || window.event;

      //       console.log("teacher")
      //       console.log(e)
      //        that.websocket.close();
      //       that.saveTelecastTeacherHour();
      //       return '关闭提示';
      //     }
      //   }else{
      //    this.websocket.close();
      // }
       },
       fun1(){
         //恢复socket
         this.websocket = new WebSocket('wss://www.wuhousmartedu.com/socket/webSocket');
         this.websocket.onopen=()=>{
            console.log('再次建立连接')
         }
         this.websocket.onmessage=(ev)=>{
           if(this.isJson(ev.data)){
             this.chats.push(JSON.parse(ev.data))
           }
         }
       },
      // 获取资源详情
      async resourceDetails() {
        let telecastId = this.$route.query.telecastId
        const {
          data
        } = await getYunTelecastLubo_api({
          "telecastId": telecastId
        });
        this.playerOptions.sources[0].src = data.resourcesUrl;
        this.resourceInfo = data;
      },
      startPlay() {
        this.timerId = setInterval(() => {
          this.timer++
        }, 1000)
      },
      pausePlay(){
        clearInterval(this.timerId);
      },
      /* 视频结束播放*/
      endedPlay() {
        if (sessionGet('userInfo').role_name == 'teacher') {
          this.saveTelecastTeacherHour();
        }
        clearInterval(this.timerId);
      },
      /* 点击结束播放*/
      endPlay() {
        if (sessionGet('userInfo').role_name == 'teacher') {
          let str = '是否结束观看？';
          if (this.timer - this.duration < 0) {
            str = '您的观看时长不足一个学时，是否退出直播间？'
          }
          this.$confirm(str, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.websocket.close();
            this.playerOptions.sources[0].src = [];
            this.playerOptions.sources[0].src = this.resourceInfo.resourcesUrl;
            //保存计时
            this.saveTelecastTeacherHour();
            //取消计时器
            clearInterval(this.timerId);
            /* 跳转到个人中心页面*/
            this.$router.go(-1);
            // if (sessionGet('userInfo').role_name === 'teacher') {
            //   this.$router.push('/mineTeacher')
            // } else {
            //   this.$router.push('/mine')
            // }
          }).catch(() => {
            return
          });
        }else{
          this.websocket.close();
          this.playerOptions.sources[0].src = [];
          this.playerOptions.sources[0].src = this.resourceInfo.resourcesUrl;
          /* 跳转到个人中心页面*/
          // if (sessionGet('userInfo').role_name === 'teacher') {
          //   this.$router.push('/mineTeacher')
          // } else {
          //   this.$router.push('/mine')
          // }
          this.$router.go(-1);
        }
      },
      /* 视频计时*/
      saveTelecastTeacherHour() {
        if (sessionGet('userInfo').role_name == 'teacher') {
          saveTelecastTeacherHour_api({
            type: 2,
            resourcesId: this.resourceInfo.id,
            length: this.timer,
            date: dateFormat('yyyy-MM-dd hh:mm:ss'),
            userId: sessionGet('userInfo').user_id
          });
        }
      },
      chooseEmoji(emoji) {
        this.form.inputValue+=emoji;
      },
      //处理聊天emoji显示
      messageProcessing(strCont){
   var str = strCont.match(/\[(.+?)\]/g); //regex2 = /\[(.+?)\]/g; // [] 中括号；regex3 = /\{(.+?)\}/g;  // {} 花括号，大括号
if(str){
  str.map(val=>{
    var reg=new RegExp("\\"+val,"g");
    var strimg='<img width="30px" align="absmiddle" src='+emojiUrl+emojiMap[val]+'>';
   strCont=strCont.replace(reg, strimg);
  })
}
return strCont;
      },
      //判断是否为json
isJson(str){
  if(isNaN(Number(str))){
    try{JSON.parse(str);
    return true;
    }catch(e){
      return false;
    }
  }
  return false;
},
      //发送聊天
      sendMsg(){
        let data={
          userName:sessionGet('userInfo').nick_name,
          content:this.form.inputValue,
          createTime:dateFormat('yyyy-MM-dd hh:mm:ss'),
          telecastId:this.$route.query.telecastId,
          createUserId:sessionGet('userInfo').user_id
        }
        if(this.form.inputValue.trim().length>0){
          //添加记录
          saveTelecastChat_api(data).then(res=>{
            if(res.success){
             // this.chats.push(data);
              this.websocket.send(JSON.stringify(data));
              this.form.inputValue='';
            }else{
              this.$message.error('消息发送失败');
            }
          })

        }
      }
    }
  }
</script>
<style scoped>
  .slide-fade-enter-active {transition: all .2s ease;}
  .slide-fade-leave-active {transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);}
  .slide-fade-enter, .slide-fade-leave-to{transform: translateX(5px);opacity: 0;}
  .emojiBox {
    border: 1px #000 solid;
    height: 182px;
    overflow: scroll;
    width: 400px;
    position: absolute;
    padding: 10px;
    right: 12%;
   bottom: 200px;
    z-index: 999;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .emojiBox img{
    width: 30px;
    cursor: pointer;
  }
  ::deep.el-tabs__nav-scroll {


    height: 60px;
    line-height: 60px;


  }

  ::deep.el-tabs__nav-wrap {
    display: flex;
    justify-content: center;
  }

  ::deep.el-tabs__item {
    color: #8a9099;
    font-size: 18px;
    font-weight: 500;
  }

  ::deep.el-tabs__nav-wrap::after {
    background-color: #454c5e;
  }

  ::deep.el-tabs__item:hover {
    color: #8a9099;
  }

  ::deep .el-tabs__item.is-active {
    color: #fff;
  }

  ::deep.el-tabs {
    height: 94vh;
    background-color: #383f54;
  }

  ::deep.el-tabs__active-bar {
    background-color: #fff;
  }

  .logo {
    margin-right: 10px;
    height: 80%;
    vertical-align: bottom;
    border-radius: 5px;
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
  }
</style>
